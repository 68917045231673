/* eslint-disable filenames/match-exported */
/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Heading } from '@datacamp/waffles/heading';
import { Link } from '@datacamp/waffles/link';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import type { TFunction } from 'i18next';
import React from 'react';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { isTeachPreview } from '../../helpers/isTeachPreview';
import { refreshPage } from '../../helpers/navigation';
import { getModalHooked } from '../../helpers/user';
import { I18N_PLACEHOLDER } from '../../i18n';
import type { UiTheme } from '../ThemeToggler';

// eslint-disable-next-line no-shadow
export enum MuxStatusMessage {
  ActivityReadTimeout = 'activityReadTimeout',
  ActivityTimeout = 'activityTimeout',
  ActivityWriteTimeout = 'activityWriteTimeout',
  CodeTimedOut = 'codeTimedOut',
  DockerCrashed = 'dockerCrashed',
  DockerFailedToRun = 'dockerFailedToRun',
  Expired = 'expired',
  ExplicitlyStopped = 'explicitlyStopped',
  FailedToGetSession = 'failedToGetSession',
  InitCodeTimedOut = 'initCodeTimedOut',
  NotUsed = 'notUsed',
  OtherSessionRequested = 'otherSessionRequested',
  OtherSessionStarted = 'otherSessionStarted',
  ReplClosed = 'replClosed',
  WrongSessionType = 'Wrong session type',
}

type ServerIssueMessageProps = {
  knownIssue: any;
  showModal: any;
  uiTheme: UiTheme;
};

const ServerIssueMessage: React.FC<ServerIssueMessageProps> = ({
  knownIssue,
  showModal,
  uiTheme,
}) => {
  const { t } = useTranslation();
  const isDarkMode = uiTheme === 'DARK';

  if (knownIssue) {
    return (
      <Paragraph inverted={isDarkMode}>
        <Trans
          t={t}
          i18nKey="ServerIssueMessage.knownIssue"
          component={{
            statusPageLink: (
              <Link
                href="http://status.datacamp.com/"
                target="_blank"
                inverted={isDarkMode}
              >
                {I18N_PLACEHOLDER}
              </Link>
            ),
          }}
        />
      </Paragraph>
    );
  }
  return (
    <Paragraph inverted={isDarkMode}>
      <Trans
        t={t}
        i18nKey="ServerIssueMessage.issue"
        components={{
          reportLink: (
            <Link href="#" onClick={showModal} inverted={isDarkMode}>
              {I18N_PLACEHOLDER}
            </Link>
          ),
        }}
      />
    </Paragraph>
  );
};

type TextComponentProps = {
  children?: React.ReactNode;
  showModal: (...args: any[]) => any;
  systemStatus: {
    description?: string;
    indicator?: string;
  };
  uiTheme: UiTheme;
};

const TextComponent: React.FC<TextComponentProps> = ({
  children,
  showModal,
  systemStatus,
  uiTheme,
}) => (
  <div>
    {children}
    <ServerIssueMessage
      knownIssue={systemStatus.indicator !== 'none'}
      showModal={showModal}
      uiTheme={uiTheme}
    />
  </div>
);

type ReconnecterProps = {
  backendSession: {
    message?: MuxStatusMessage;
  };
  exercise: {
    language?: string;
  };
  // eslint-disable-next-line @typescript-eslint/ban-types
  hintAndSolution: {};
  // import ReactRouterPropTypes from 'react-router-prop-types';
  // TODO: ReactRouterPropTypes.history
  history: any;
  onClicked?: () => void;
  onShowReconnecter?: (...args: any[]) => any;
  sessionIsBroken: boolean;
  shouldRefreshPageOnRestart: boolean;
  showIssueReporter: (...args: any[]) => any;
  showLoginModal: (...args: any[]) => any;
  showSolution: (...args: any[]) => any;
  startSession: (...args: any[]) => any;
  systemStatus: {
    description?: string;
    indicator?: string;
  };
  t: TFunction;
  uiTheme: UiTheme;
  // eslint-disable-next-line @typescript-eslint/ban-types
  user: {};
};

export const getRestartInfo = (
  t: TFunction,
  multiplexerStatusMessage?: MuxStatusMessage,
): { message: string } => {
  if (multiplexerStatusMessage?.startsWith('cleaned:') ?? false) {
    return {
      message: t('Reconnecter.activityWriteTimeoutMessage'),
    };
  }
  switch (multiplexerStatusMessage) {
    case MuxStatusMessage.ActivityTimeout:
    case MuxStatusMessage.ActivityReadTimeout:
    case MuxStatusMessage.ActivityWriteTimeout:
      return {
        message: t('Reconnecter.activityWriteTimeoutMessage'),
      };
    case MuxStatusMessage.DockerFailedToRun:
      return {
        message: t('Reconnecter.dockerFailedToRunMessage'),
      };
    case MuxStatusMessage.ExplicitlyStopped:
      return {
        message: t('Reconnecter.explicitlyStoppedMessage'),
      };
    case MuxStatusMessage.DockerCrashed:
    case MuxStatusMessage.ReplClosed:
      return {
        message: t('Reconnecter.dockerCrashedMessage'),
      };
    case MuxStatusMessage.InitCodeTimedOut:
      return {
        message: t('Reconnecter.initCodeTimedOutMessage'),
      };
    case MuxStatusMessage.OtherSessionStarted:
    case MuxStatusMessage.OtherSessionRequested:
      return {
        message: t('Reconnecter.otherSessionStartedMessage'),
      };
    case MuxStatusMessage.NotUsed:
      return {
        message: t('Reconnecter.notUsedMessage'),
      };
    case MuxStatusMessage.FailedToGetSession:
      return {
        message: t('Reconnecter.failedToGetSessionMessage'),
      };
    case MuxStatusMessage.Expired:
      return {
        message: t('Reconnecter.expiredMessage'),
      };
    case MuxStatusMessage.WrongSessionType:
    default:
      return {
        message: t('Reconnecter.defaultMessage'),
      };
  }
};

type ReconnecterState = { isClosed: boolean };

export class ReconnecterComponent extends React.Component<
  ReconnecterProps,
  ReconnecterState
> {
  constructor(props: ReconnecterProps) {
    super(props);
    this.state = { isClosed: false };
  }

  componentDidUpdate(): void {
    if (this.props.sessionIsBroken && this.props.onShowReconnecter) {
      this.props.onShowReconnecter();
    }
  }

  getTextComponent(): JSX.Element {
    const {
      backendSession,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{ language?:... Remove this comment to see the full error message
      exercise: { id, language },
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'xp' does not exist on type '{}'.
      hintAndSolution: { isSolution, visible, xp },
      showIssueReporter,
      showLoginModal,
      showSolution,
      systemStatus,
      t,
      user,
    } = this.props;
    const hookedFn = getModalHooked(user, showIssueReporter, showLoginModal);

    if (backendSession.message === MuxStatusMessage.CodeTimedOut) {
      return (
        <TextComponent
          showModal={hookedFn}
          systemStatus={systemStatus}
          uiTheme={this.props.uiTheme}
        >
          <Paragraph inverted={this.props.uiTheme === 'DARK'}>
            {t('Reconnecter.codeTimedOutMessage')}
          </Paragraph>
          {visible && (
            <Paragraph inverted={this.props.uiTheme === 'DARK'}>
              <Trans
                t={t}
                i18nKey={
                  isSolution
                    ? 'Reconnecter.codeTimedOutSolutionText'
                    : 'Reconnecter.codeTimedOutHintText'
                }
                components={{
                  btn: (
                    <Link
                      href="#"
                      onClick={() =>
                        showSolution(
                          { language, isSolution, id, xp },
                          this.onRestartSession,
                        )
                      }
                      inverted={this.props.uiTheme === 'DARK'}
                    >
                      {I18N_PLACEHOLDER}
                    </Link>
                  ),
                }}
              />
            </Paragraph>
          )}
        </TextComponent>
      );
    }

    return (
      <TextComponent
        showModal={hookedFn}
        systemStatus={systemStatus}
        uiTheme={this.props.uiTheme}
      >
        <Heading size="large" inverted={this.props.uiTheme === 'DARK'}>
          {getRestartInfo(t, backendSession.message).message}
        </Heading>
      </TextComponent>
    );
  }

  onRestartSession = (): void => {
    const { exercise, onClicked, shouldRefreshPageOnRestart, startSession } =
      this.props;
    if (shouldRefreshPageOnRestart) {
      refreshPage();
    } else {
      startSession({
        ...exercise,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'pre_exercise_code' does not exist on typ... Remove this comment to see the full error message
        pec: exercise.pre_exercise_code,
        force_new: true,
      });
      if (onClicked) {
        onClicked();
      }
    }
  };

  onClose = (): void => {
    this.setState({ isClosed: true });
  };

  render(): JSX.Element | null {
    if (!this.props.sessionIsBroken || this.state.isClosed) {
      return null;
    }

    return (
      <div
        id="wrapper"
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          zIndex: 10,
          position: 'absolute',
          textAlign: 'center',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          padding: tokens.spacing.large,
          gap: tokens.spacing.small,
          backgroundColor:
            this.props.uiTheme === 'DARK'
              ? tokens.colors.navy
              : tokens.colors.white,
        }}
      >
        {this.getTextComponent()}
        <Button
          onClick={this.onRestartSession}
          variant="primary"
          data-cy="restart-session-button"
          inverted={this.props.uiTheme === 'DARK'}
        >
          {this.props.t('Reconnecter.primaryButton')}
        </Button>
        {isTeachPreview(this.props.history.location.pathname) && (
          <Button
            onClick={this.onClose}
            variant="primary"
            inverted={this.props.uiTheme === 'DARK'}
          >
            {this.props.t('Reconnecter.closeButton')}
          </Button>
        )}
      </div>
    );
  }
}

// @ts-expect-error fix later, leftover from javascript
export default withRouter(withTranslation()(ReconnecterComponent));
