/** @jsx jsx */
import { ErrorBoundary } from '@datacamp/le-shared-components';
import { jsx } from '@emotion/react';
import raven from 'raven-js';
import React, { Suspense } from 'react';

import DCSpinner from '../DCSpinner';

import type { MarkdownRendererProps } from './MarkdownRenderer';

const MarkdownRenderer = React.lazy(
  () =>
    import(/* webpackChunkName: "markdown-renderer" */ './MarkdownRenderer'),
);

export type MarkdownRendererLoaderProps = MarkdownRendererProps & {
  id: number | string;
};

const MarkdownRendererLoader: React.FC<MarkdownRendererLoaderProps> = (
  props,
) => (
  <ErrorBoundary onErrorReported={raven.captureException}>
    <Suspense
      fallback={
        <div css={{ width: '100%', height: '100%' }}>
          <DCSpinner />
        </div>
      }
    >
      <MarkdownRenderer {...props} key={props.id} />
    </Suspense>
  </ErrorBoundary>
);

export default MarkdownRendererLoader;
