import type { Resource } from '@datacamp/exercise-contract';
import React from 'react';
import { connect } from 'react-redux';

import {
  getUrlParamsFromVideoExercise,
  getVideoUrl,
} from '../../helpers/getVideoUrl';
import trackABExperiment from '../../helpers/trackABExperiment';
import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import ExerciseHandler from './ExerciseHandler';

function ExerciseHandlerImmutableWrapper({
  chapterImmutable,
  currentSubExerciseImmutable,
  exerciseImmutable,
  exerciseProgressImmutable,
  nextIdsByExerciseTypeImmutable,
  ...props
}: any) {
  const exercise = exerciseImmutable.toJS();
  const currentSubExercise = currentSubExerciseImmutable.toJS();
  const chapter = chapterImmutable.toJS();
  const exerciseProgress = exerciseProgressImmutable.toJS();
  const nextIdsByExerciseType = nextIdsByExerciseTypeImmutable.toJS();

  return (
    <ExerciseHandler
      exercise={exercise}
      currentSubExercise={currentSubExercise}
      chapter={chapter}
      exerciseProgress={exerciseProgress}
      nextIdsByExerciseType={nextIdsByExerciseType}
      {...props}
    />
  );
}

const mapStateToProps = (state: any) => ({
  isApplicationBooted: selectors.selectIsApplicationBooted(state),
  exerciseImmutable: selectors.selectExercise(state),
  currentSubExerciseImmutable: selectors.selectCurrentSubExercise(state),
  chapterImmutable: selectors.selectChapter(state),
  exerciseProgressImmutable: selectors.selectExerciseProgress(state),
  progressIndicatorVisible: selectors.selectProgressIndicatorVisible(state),

  // Required for external exercises
  isActive: true,

  courseId: selectors.selectCourse(state).get('id'),
  chapterId: selectors.selectChapter(state).get('id'),
  exerciseId: selectors.selectExercise(state).get('id'),

  nextIdsByExerciseTypeImmutable:
    selectors.selectNextExternalExerciseIdsByExerciseTypes(state),

  email: selectors.selectUserSettings(state).get('email'),
  userId: selectors.selectUserSettings(state).get('id'),

  hasActiveSubscription: selectors
    .selectUserSettings(state)
    .get('has_active_subscription', false),

  isLoggedIn: selectors.selectIsUserLoggedIn(state),
  pathLanguage: selectors.selectPathLanguage(state),

  authenticationToken: selectors
    .selectUserSettings(state)
    .get('authentication_token'),

  multiplexerUrl: selectors.selectMultiplexerUrl(state),
  multiplexerRuntimeConfig: selectors.selectRuntimeConfig(state),
  language: selectors.selectLanguage(state),

  canRateChapter: selectors.selectCanRateChapter(state),
  resources: [
    {
      data: {
        url: getVideoUrl(
          getUrlParamsFromVideoExercise(
            selectors.selectPrecedingVideoExercise(state),
          ),
        ),
        label: 'Rewatch demo',
      },
      type: 'VIDEO',
    },
    {
      data: {
        url: selectors.selectChapter(state).get('slides_link'),
      },
      type: 'SLIDES',
    },
  ] as Resource[],
});

const mapDispatchToProps = (dispatch: any) => ({
  startSession: (sessionSettings: any) =>
    dispatch(actions.epicStartSession(sessionSettings)),

  // Required for external exercises
  onSubmitted: ({ code, correct, exerciseType, message, xp }: any) =>
    dispatch(
      actions.submitExternalExercise({
        message,
        code,
        correct,
        xp,
        exerciseType,
      }),
    ),

  onMultiplexerStatusUpdate: ({ error, message, status, statusCode }: any) =>
    dispatch(
      actions.epicUpdateBackendStatus({ status, statusCode, message, error }),
    ),

  onXpUpdate: ({ currentXp }: any) =>
    dispatch(actions.updateExternalExerciseXp({ currentXp })),

  onXpStandaloneExerciseUpdate: ({ xp }: any) =>
    dispatch(actions.updateExternalExerciseXp({ currentXp: xp })),

  onNext: () => dispatch(actions.nextExternalExercise()),

  showChapterRating: (courseId: any, userId: any) => {
    const bucket = trackABExperiment('skipChapterRating', courseId, userId);
    if (bucket === 'control') {
      return dispatch(
        actions.showModal({
          modal: {
            ...selectors.MODAL_TYPE.CHAPTER_RATING,
            onFinish: () => {
              dispatch(actions.nextExternalExercise());
              dispatch(actions.removeModal());
            },
          },
        }),
      );
    }
    return dispatch(actions.nextExternalExercise());
  },
});

const mergeProps = (stateProps: any, dispatchProps: any, ownProps: any) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onNext: stateProps.canRateChapter
    ? () =>
        dispatchProps.showChapterRating(stateProps.courseId, stateProps.userId)
    : dispatchProps.onNext,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ExerciseHandlerImmutableWrapper);
